<template>
  <div class="anchor-pop-edit-container">
    <a-modal
      title="使用记录"
      :visible="visible"
      :confirm-loading="confirmLoading"
      @ok="handleOk"
      @cancel="handleCancel"
      width="700px"
    >

      <div class="ye-box">当前已使用：{{current_use}}  剩余：{{surplus}}</div>
      <a-table
        :columns="columns"
        :data-source="dataSource"
        :loading="loading"
        :pagination="pageOptions"
        @change="handleTableChange"
        size="middle"
      >

      </a-table>
    </a-modal>
  </div>
</template>

<script>

const columns = [
  {
    title: "UID",
    dataIndex: "users.uid",
  },
  {
    title: "经纪人",
    dataIndex: "users.uname",
  },
  {
    title: "UID",
    dataIndex: "anchor.uid",
  },
  {
    title: "主播",
    dataIndex: "anchor.uname",
  },
  {
    title: "使用星光",
    dataIndex: "num",
    sorter: (a, b) => a.num - b.num
  },
  {
    title: "下单时间",
    dataIndex: "created_at",
    sorter: (a, b) => a.created_at - b.created_at
  }
];

const pageOptions = {
  defaultCurrent: 1,
  pageSize: 10,
  pageSizeOptions:['10','20', '30', '50'],
  showSizeChanger:true,
  showQuickJumper:true,
  showTotal: total => `共${total}条`
};

export default {
  data() {
    return {
      current_use:0,
      surplus:0,
      confirmLoading: false,
      pageOptions,
      loading: true,
      dataSource:[],
      columns,
      queryParam:{
        page: 1,//第几页
        perPage: 20,//每页中显示数据的条数
        bili_id:''
      },
    };
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    row:{
      type:Object,
      default:()=>{
        return {}
        }
    }
  },
  computed: {},
  mounted() {
    this.loading = false;
    this.queryParam.bili_id=this.row.uid
    this.getData();
  },
  methods: {
    handleTableChange(pagination, filters, sorter) {
        console.log('sorter',sorter)
        this.pageOptions.current = pagination.current
        this.pageOptions.pageSize = pagination.pageSize
        this.queryParam.page = pagination.current
        this.queryParam.perPage = pagination.pageSize
        this.getData()
    },
    getData(){
      this.$api.post('v2/starShine/starlightLogs',this.queryParam).then((res) => {
        if(res.code==200){
          this.surplus=res.data.surplus
          this.current_use=res.data.current_use
          this.dataSource = res.data.list.data;
          const pagination = { ...this.pageOptions }
          pagination.total = res.data.list.total
          this.pageOptions = pagination
          console.log(this.pagination)
        }else{
          this.$message.error(res.message)
        }
      })
    },
    handleOk(e) {
      this.$emit("handleOk");
    },
    handleCancel(e) {
      this.$emit("handleCancel");
    }
  },
};
</script>

<style lang="less" scoped>
.anchor-pop-edit-container {
}

</style>
