
const pageOptions = {
  defaultCurrent: 1,
  pageSize: 20,
  pageSizeOptions:['10','20', '30', '50'],
  showSizeChanger:true,
  showQuickJumper:true,
  showTotal: total => `共${total}条`
};

import tool from '@/utils/tool'
import moment from 'moment'
const MixinList={
    data(){
      return {
        title:'',
        loading: false,
        dataSource:[],
        pageOptions,
        queryParam:{
          page: 1,//第几页
          perPage: 20//每页中显示数据的条数
        },
        dateData:{
          start: '',
          end: '',
        },
        visible: false,
        editVisible: false,
        confirmLoading: false,
        objEdit:{},
        isGet:true,
        account:0,
        clear_time:0,
        send_time:0,
        guild:'',
        search:1500,
        searchEnd:1500,
        dateTime:'',
        btn:{
          isEdit:false,
          isList:false,
          isLog:false
        }
      }
    },
    components: {
      
    },
    activated(){
      this.getData()
    },
    mounted (){
      this.dateData.start = moment().startOf("month").format("YYYY-MM-DD");
      this.dateData.end = moment().add(-1, 'days').format("YYYY-MM-DD");
      this.title=this.$route.name
      this.queryParam.page = 1
      tool.$on('search',(res)=>{
        this.queryParam=res
        this.queryParam.page = 1
        this.getData()
      })

      tool.$on('clear',(res)=>{
        this.queryParam.page = 1
        delete this.queryParam.search;
        this.getData()
      })

      if(this.isGet){
        this.getData()
      }
    },
    methods:{
      async getData() {
        this.loading = true
        let resp=null
        resp = await this.$api.get(this.dataUrl,{params:this.queryParam})
        this.searchEnd = this.search
        this.loading = false
        this.dataSource = [];
        if (resp.code == 200) {
          this.account= resp.data.account
          this.clear_time= resp.data.clear_time
          this.send_time= resp.data.send_time
          this.guild= resp.data.guild
          const pagination = { ...this.pageOptions }
          pagination.total = resp.data.list.total
          pagination.current_page = 1
          this.pageOptions = pagination
          this.dataSource = resp.data.list.data.map((d) => {
            d.key = d.id
            return d
          })
        } else this.$message.error(resp.message)
      },
      handleTableChange(pagination, filters, sorter) {
        console.log('sorter',sorter)
        this.pageOptions.current = pagination.current
        this.pageOptions.pageSize = pagination.pageSize
        this.queryParam.page = pagination.current
        this.queryParam.perPage = pagination.pageSize
        console.log(filters, sorter)
        if(sorter){
          this.queryParam.order_by = sorter.field
          if (sorter.order == 'ascend') {
            this.queryParam.sort = 'asc'
          } else if (sorter.order == 'descend') {
            this.queryParam.sort = 'desc'
          } else {
            delete this.queryParam.order_by;
            delete this.queryParam.sort;
          }
        }

        this.getData()
      },
      toEdit(row) {
        this.editVisible=true;
        this.objEdit= JSON.parse(JSON.stringify(row));
      },
      toDelete(row){
        this.$api
          .post(this.delUrl,{id:row.id})
          .then((resp) => {
            if (resp.code == 200) {
              this.$message.success('操作成功');
              this.getData()
            } else {
              this.$message.error(resp.message);
            }
          })
          .catch((e) => {
            throw e;
          });
      },
      onSearch(keyword){
        this.queryParam.page = 1
        this.queryParam.keyword = keyword
        if(keyword==''){
          delete this.queryParam.keyword;
          this.queryParam.page = 1
          this.pageOptions.current = 1
        }
        this.getData()
      },
      onClear(){
        delete this.queryParam.search;
        this.queryParam.page = 1
        this.getData()
      },
      changeMonth(date){
        this.title=date
        this.queryParam.page = 1
        this.queryParam.date = date.replace('-','')
        if(date==''){
          delete this.queryParam.date;
          this.queryParam.page = 1
          this.pageOptions.current = 1
        }
        this.getData()
      },
      onSetNull(){
        this.loading = false
        this.queryParam.page = 1
        this.dataSource = [];
      }




    }
}


export { MixinList}
