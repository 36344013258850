<template>
  <div class="anchor-pop-edit-container">
    <a-modal
      title="主播列表1"
      :visible="visible"
      :confirm-loading="confirmLoading"
      @ok="handleOk"
      @cancel="handleCancel"
      width="700px"
    >
      <a-table
        :columns="columns"
        :data-source="row"
        :loading="loading"
        size="middle"
      >

      </a-table>
    </a-modal>
  </div>
</template>

<script>

const columns = [
  {
    title: "UID",
    dataIndex: "uid",
  },
  {
    title: "昵称",
    dataIndex: "uname",
  },
  // {
  //   title: "头像",
  //   dataIndex: "guard_count",
  // },
  {
    title: "当前流水",
    dataIndex: "flow",
  },
];

const pageOptions = {
  defaultCurrent: 1,
  pageSize: 20,
  pageSizeOptions:['10','20', '30', '50'],
  showSizeChanger:true,
  showQuickJumper:true,
  showTotal: total => `共${total}条`
};

export default {
  data() {
    return {
      confirmLoading: false,
      pageOptions,
      loading: true,
      dataSource:[],
      columns,
    };
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    row:{
      type:[Object,Array],
      default:()=>{
        return {}
        }
    }
  },
  computed: {},
  mounted() {
    this.loading = false;
    // this.getData();
  },
  methods: {
    getData(){
      this.$api.get('v2/broker/newAnchorList',{params:this.row}).then((res) => {
        if(res.code==200){
          this.dataSource = res.data.list;
          this.pageOptions = res.data.meta.pagination
        }else{
          this.$message.error(res.message)
        }
      })
    },
    handleOk(e) {
      this.$emit("handleOk");
    },
    handleCancel(e) {
      this.$emit("handleCancel");
    }
  },
};
</script>

<style lang="less" scoped>
.anchor-pop-edit-container {
}

</style>
